import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// import { isCookieAccepted } from 'components/Cookies/Cookies'; // Замените на правильный путь к вашему Cookies компоненту

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,

    detection: {
      order: ['localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      // caches: ['localStorage'],
      // caches: isCookieAccepted() ? ['localStorage'] : [], // Если куки разрешены, то используем localStorage, иначе не используем

      lookupLocalStorage: 'i18nextLng',
    },

    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },

    interpolation: {
      escapeValue: false,
    },
  });
